@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
  font-family: "Open Sans", sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (max-width: 1024px) {}

.view-more{
  background:rgba(16 18 27 / 20%);
  backdrop-filter: blur(15px);
 -webkit-backdrop-filter: blur(20px);
   font-size: 600;
   padding-left: 9px;
   padding-right: 9px;
   padding-top: 3px;
   padding-bottom: 3px;
   border-radius: 5px;

   color: azure;
}
.viewmore{
  background:rgba(16 18 27 / 35%);
  backdrop-filter: blur(25px);
 -webkit-backdrop-filter: blur(25px);
}
.view-more:hover{
  background:rgba(16 18 27 / 50%);
  backdrop-filter: blur(20px);
 -webkit-backdrop-filter: blur(20px);
}

